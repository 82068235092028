import React from 'react'
import useDocumentHead from '../hooks/useDocumentHead'
import { Box, Container, Typography, Grid, Card, CardContent, Avatar, Paper, Divider, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WorkIcon from '@mui/icons-material/Work'
import SchoolIcon from '@mui/icons-material/School'
import BusinessIcon from '@mui/icons-material/Business'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects'
import JustinImage from '../assets/images/justin.jpg'
import JonathanImage from '../assets/images/jonathan.jpg'
import RobynneImage from '../assets/images/robynne.jpg'
import HeroImage from '../assets/images/hero.png'
import WorkImage from '../assets/images/lawyer.png'
import RichardImage from '../assets/images/richard.jpg'

// Team member data
const teamMembers = [
  {
    name: 'Justin Zollars',
    role: 'CEO & Co-Founder',
    bio: 'Engineer with over 15 years of experience building production-quality Python/Go-based data systems and cloud solutions, complemented by a strong scientific foundation',
    avatar: 'A',
    image: JustinImage,
  },
  // {
  //   name: 'Jonathan Lancar',
  //   role: 'CTO & Co-Founder',
  //   bio: 'Experienced Engineering Manager with a proven track record of leading technical teams and delivering scalable software solutions. Skilled in managing complex projects, fostering collaboration across teams, and developing platforms that solve challenging technical problems. ',
  //   avatar: 'S',
  //   image: JonathanImage,
  // },
  {
    name: 'Robynne Merguerdijian',
    role: 'COO & Co-Founder',
    bio: 'Experienced regulatory compliance, procurement, warehouse & technician with over 15 years of industry expertise. Proven leadership as COO in a startup environment with proficiency in managing compliance and operational efficiency.',
    avatar: 'M',
    image: RobynneImage,
  },
  {
    name: 'Richard Appel',
    role: 'CCS',
    bio: 'Chief of Customer Success with 15+ years leading real estate development, contractor management, and CRM-driven operations. Expertise in customer satisfaction, and scaling high-growth startups with a strong foundation in tech and process optimization.',
    avatar: 'M',
    image: RichardImage,
  },
]

// Values data
const companyValues = [
  {
    title: 'Customer-Centric Innovation',
    description: 'We design our solutions by deeply understanding the challenges that support teams face daily.',
  },
  {
    title: 'Human-AI Collaboration',
    description: 'We believe in augmenting human capabilities, not replacing them. Technology should empower people.',
  },
  {
    title: 'Continuous Improvement',
    description: "We're committed to constantly evolving our platform based on customer feedback and industry trends.",
  },
  {
    title: 'Transparency & Trust',
    description: 'We build relationships based on clear communication, honesty, and delivering on our promises.',
  },
]

// Timeline data
const companyTimeline = [
  {
    year: '2024',
    title: 'The Beginning',
    description: 'TicketSidekick was founded after our co-founders experienced firsthand the challenges of scaling support operations.',
  },
  {
    year: '2025',
    title: 'First Enterprise Client',
    description: 'Launched our first major implementation with a Fortune 500 technology company, reducing their ticket resolution time by 40%.',
  },
  {
    year: '2025',
    title: 'Growing Team',
    description: 'Doubled our team size and opened our headquarters in Austin to accommodate our growing client base.',
  },
]

const AboutPage = () => {
  const theme = useTheme()

  useDocumentHead({
    title: 'About TicketSidekick | Transforming Support Operations with AI',
    description: "Learn about TicketSidekick's mission, story, values, and team. We're transforming support operations with AI-powered solutions.",
  })

  return (
    <Box sx={{ overflow: 'hidden' }}>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          color: 'white',
          py: { xs: 10, md: 14 },
          overflow: 'hidden',
        }}
      >
        {/* Background gradient */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle at top right, rgba(77, 123, 252, 0.15) 0%, rgba(17, 24, 39, 0) 70%)',
          }}
        />

        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              component="h1"
              gutterBottom
              align="center"
              sx={{
                fontSize: { xs: '2.5rem', md: '3.5rem' },
                mb: 3,
              }}
            >
              About TicketSidekick
            </Typography>
            <Typography variant="h5" align="center" sx={{ maxWidth: 800, mx: 'auto', fontWeight: 400 }}>
              We're on a mission to transform support operations by combining human expertise with AI capabilities
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* Our Story Section */}
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h3" component="h2" gutterBottom>
              Our Story
            </Typography>
            <Typography variant="body1" paragraph color="text.secondary">
              TicketSidekick was born from a simple observation: support engineers spend too much time on repetitive tasks instead of focusing on complex problems that require
              human expertise.
            </Typography>
            <Typography variant="body1" paragraph color="text.secondary">
              Founded in 2025 by former Adobe and Just, Inc. engineers and AI specialists, we set out to create a solution that would augment support teams rather than replace
              them, making their work more efficient and meaningful.
            </Typography>
            <Typography variant="body1" paragraph color="text.secondary">
              Today, we're helping companies across industries transform their support operations, reduce response times, and improve customer satisfaction while enabling their
              support teams to focus on what they do best: solving complex problems with creativity and empathy.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={16}
              sx={{
                p: 4,
                borderRadius: 4,
                background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.7) 0%, rgba(31, 41, 55, 0.5) 100%)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                height: 350,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 20px 40px rgba(0, 0, 0, 0.3)',
                overflow: 'hidden', // Add this to make sure the image doesn't overflow
              }}
            >
              <Box
                component="img"
                src={HeroImage}
                alt="Company founders"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover', // This ensures the image covers the container nicely
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      {/* Our Mission Section */}
      <Box sx={{ py: 10, position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(135deg, rgba(17, 24, 39, 0.95) 0%, rgba(31, 41, 55, 0.95) 100%)',
            zIndex: -1,
          }}
        />

        <Container maxWidth="lg">
          <Typography variant="h3" component="h2" gutterBottom align="center" sx={{ mb: 5 }}>
            Our Mission
          </Typography>
          <Typography variant="h4" align="center" sx={{ maxWidth: 800, mx: 'auto', mb: 6, fontWeight: 600 }}>
            To break organizational silos enabling support teams to deliver exceptional customer experiences at scale while driving operational efficiency and business growth.
          </Typography>

          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: 'center' }}>
                <EmojiObjectsIcon sx={{ fontSize: 60, color: theme.palette.primary.main, mb: 2 }} />
                <Typography variant="h5" gutterBottom>
                  Innovation
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Creating cutting-edge solutions that anticipate and address the evolving needs of support teams.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: 'center' }}>
                <BusinessIcon sx={{ fontSize: 60, color: theme.palette.primary.main, mb: 2 }} />
                <Typography variant="h5" gutterBottom>
                  Efficiency
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Streamlining support operations to maximize productivity and minimize response times.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: 'center' }}>
                <SchoolIcon sx={{ fontSize: 60, color: theme.palette.primary.main, mb: 2 }} />
                <Typography variant="h5" gutterBottom>
                  Empowerment
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Equipping support professionals with tools that enhance their capabilities and job satisfaction.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Values */}
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Typography variant="h3" component="h2" gutterBottom align="center" sx={{ mb: 6 }}>
          Our Values
        </Typography>

        <Grid container spacing={4}>
          {companyValues.map((value, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" component="h3" gutterBottom>
                    {value.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {value.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Company Timeline */}
      <Box sx={{ py: 10, position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.95) 0%, rgba(17, 24, 39, 0.95) 100%)',
            zIndex: -1,
          }}
        />

        <Container maxWidth="lg">
          <Typography variant="h3" component="h2" gutterBottom align="center" sx={{ mb: 6 }}>
            Our Journey
          </Typography>

          <Box sx={{ position: 'relative' }}>
            {/* Vertical timeline line */}
            <Box
              sx={{
                position: 'absolute',
                left: { xs: 16, sm: 20 },
                top: 0,
                bottom: 0,
                width: 2,
                backgroundColor: theme.palette.primary.main,
                zIndex: 1,
              }}
            />

            {/* Timeline events */}
            <List>
              {companyTimeline.map((event, index) => (
                <ListItem
                  key={index}
                  sx={{
                    mb: 4,
                    pl: 0,
                    alignItems: 'flex-start',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: { xs: 42, sm: 50 },
                      position: 'relative',
                      zIndex: 2,
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        width: { xs: 32, sm: 40 },
                        height: { xs: 32, sm: 40 },
                      }}
                    >
                      {event.year.slice(2)}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h5" component="h3" gutterBottom>
                        {event.title}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          {event.year}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          {event.description}
                        </Typography>
                      </>
                    }
                    sx={{ m: 0 }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Container>
      </Box>

      {/* Team Section */}
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Typography variant="h3" component="h2" gutterBottom align="center" sx={{ mb: 6 }}>
          Meet Our Team
        </Typography>

        <Grid container spacing={4}>
          {teamMembers.map((member, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  height: '100%',
                  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardContent sx={{ p: 4, textAlign: 'center' }}>
                  <Avatar
                    src={member.image}
                    sx={{
                      width: 100,
                      height: 100,
                      mx: 'auto',
                      mb: 3,
                      boxShadow: '0 4px 20px rgba(77, 123, 252, 0.3)',
                    }}
                  />
                  <Typography variant="h7" component="h4" gutterBottom>
                    {member.name}
                  </Typography>
                  <Typography variant="subtitle1" color="primary.light" gutterBottom fontWeight={500}>
                    {member.role}
                  </Typography>
                  <Divider sx={{ my: 2, backgroundColor: 'rgba(255,255,255,0.1)' }} />
                  <Typography variant="body2" color="text.secondary">
                    {member.bio}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Why Work With Us */}
      <Box sx={{ py: 10, position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.95) 0%, rgba(17, 24, 39, 0.95) 100%)',
            zIndex: -1,
          }}
        />

        <Container maxWidth="lg">
          <Typography variant="h3" component="h2" gutterBottom align="center" sx={{ mb: 6 }}>
            Why Work With Us
          </Typography>

          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Paper
                elevation={16}
                sx={{
                  p: 0, // Remove padding to allow the image to fill the container
                  borderRadius: 4,
                  background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.7) 0%, rgba(31, 41, 55, 0.5) 100%)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  height: 350,
                  overflow: 'hidden', // Ensure the image doesn't break the rounded corners
                  boxShadow: '0 20px 40px rgba(0, 0, 0, 0.3)',
                }}
              >
                <Box
                  component="img"
                  src={WorkImage}
                  alt="Team collaboration"
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
                  borderRadius: 4,
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <List>
                    <ListItem sx={{ pb: 2 }}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={<Typography variant="h6">Customized Solutions</Typography>}
                        secondary={
                          <Typography variant="body2" color="text.secondary">
                            We tailor our platform to your specific needs and existing systems.
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem sx={{ pb: 2 }}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={<Typography variant="h6">Continuous Support</Typography>}
                        secondary={
                          <Typography variant="body2" color="text.secondary">
                            Our customer success team ensures you get maximum value from day one.
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem sx={{ pb: 2 }}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={<Typography variant="h6">Industry Expertise</Typography>}
                        secondary={
                          <Typography variant="body2" color="text.secondary">
                            Our team has deep knowledge of support operations across various industries.
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem sx={{ pb: 2 }}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={<Typography variant="h6">Measurable Results</Typography>}
                        secondary={
                          <Typography variant="body2" color="text.secondary">
                            We're committed to delivering tangible improvements in your support metrics.
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={<Typography variant="h6">Future-Proof Technology</Typography>}
                        secondary={
                          <Typography variant="body2" color="text.secondary">
                            Our platform evolves with your needs and the latest AI advancements.
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default AboutPage
