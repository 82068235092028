import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { AppBar, Box, Toolbar, Typography, Button, Container, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const navigationLinks = [
  { name: 'Features', path: '/features' },
  { name: 'Savings', path: '/calculator?r=header' },
  { name: 'About Us', path: '/about' },
  { name: 'Contact', path: '/contact' },
]

const Header = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [anchorElNav, setAnchorElNav] = useState(null)

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backdropFilter: 'blur(10px)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ py: 1 }}>
          {/* Logo - visible on all screens */}
          <SupportAgentIcon
            sx={{
              display: { xs: 'none', md: 'flex' },
              mr: 1.5,
              fontSize: 30,
              color: 'primary.main',
            }}
          />
          <Typography
            variant="h5"
            noWrap
            component={RouterLink}
            to="/"
            sx={{
              mr: 4,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 800,
              color: 'white',
              textDecoration: 'none',
              letterSpacing: '-0.02em',
            }}
          >
            TicketSidekick
          </Typography>

          {/* Mobile menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiPaper-root': {
                  backgroundColor: 'background.paper',
                  borderRadius: 2,
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                },
              }}
            >
              {navigationLinks.map(link => (
                <MenuItem key={link.name} onClick={handleCloseNavMenu} component={RouterLink} to={link.path}>
                  <Typography textAlign="center" fontWeight={500}>
                    {link.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Logo - visible on mobile */}
          <SupportAgentIcon
            sx={{
              display: { xs: 'flex', md: 'none' },
              mr: 1,
              color: 'primary.main',
            }}
          />
          <Typography
            variant="h6"
            noWrap
            component={RouterLink}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 800,
              color: 'white',
              textDecoration: 'none',
              letterSpacing: '-0.02em',
            }}
          >
            TicketSidekick
          </Typography>

          {/* Desktop navigation links - Changed to left alignment */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'flex-start', // Changed from 'center' to 'flex-start'
            }}
          >
            {navigationLinks.map(link => (
              <Button
                key={link.name}
                component={RouterLink}
                to={link.path}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: 'text.secondary',
                  display: 'block',
                  mx: 1.5,
                  fontWeight: 600,
                  fontSize: '1rem',
                  '&:hover': {
                    color: 'white',
                  },
                }}
              >
                {link.name}
              </Button>
            ))}
          </Box>

          {/* Call to action button */}
          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              component={RouterLink}
              to="/contact"
              sx={{
                mr: { xs: 0, sm: 2 },
                display: { xs: 'none', sm: 'flex' },
                borderColor: 'rgba(77, 123, 252, 0.5)',
                '&:hover': {
                  borderColor: 'primary.main',
                },
              }}
            >
              Sign Up
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              component={RouterLink} 
              to="/contact" 
              endIcon={<ArrowForwardIcon />}
              sx={{
                '& .MuiButton-endIcon': {
                  display: { xs: 'none', sm: 'flex' }
                }
              }}
            >
              {isMobile ? 'Waitlist' : 'Join the waitlist'}
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header