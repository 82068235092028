import React, { useEffect } from 'react'
import { Box, Container, Typography, Grid, Link, Divider, IconButton, useTheme } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'

const Footer = () => {
  const theme = useTheme()
  const currentYear = new Date().getFullYear()

  // Add useEffect to load Calendly scripts and styles
  useEffect(() => {
    // Add Calendly CSS
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://assets.calendly.com/assets/external/widget.css'
    document.head.appendChild(link)
    
    // Add Calendly JS
    const script = document.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.async = true
    document.body.appendChild(script)
    
    // Initialize the badge widget when script is loaded
    script.onload = () => {
      window.Calendly.initBadgeWidget({ 
        url: 'https://calendly.com/justin-ticketsidekick/15min', 
        text: 'Book a Demo 🚀', 
        color: theme.palette.primary.main, // Use your theme's primary color
        textColor: '#ffffff' 
      })
    }
    
    return () => {
      document.head.removeChild(link)
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.grey[900],
        color: 'white',
        py: 6,
        mt: 'auto',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <SupportAgentIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
              <Typography variant="h6" component="div">
                TicketSidekick
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ mb: 2, color: theme.palette.grey[400] }}>
              TicketSidekick is a smart support agent that manages tech support automatically across the entire incident lifecycle
            </Typography>
            <Box>
              <IconButton
                aria-label="LinkedIn"
                component="a"
                href="https://www.linkedin.com/company/TicketSidekick/"
                target="_blank"
                sx={{
                  color: theme.palette.grey[400],
                  '&:hover': { color: 'white' },
                }}
              >
                <LinkedInIcon />
              </IconButton>
              <IconButton
                aria-label="Twitter"
                component="a"
                href="https://x.com/TicketSide_Kick"
                target="_blank"
                sx={{
                  color: theme.palette.grey[400],
                  '&:hover': { color: 'white' },
                }}
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                aria-label="Facebook"
                component="a"
                href="https://www.facebook.com/share/1EQKftneQQ/?mibextid=wwXIfr"
                target="_blank"
                sx={{
                  color: theme.palette.grey[400],
                  '&:hover': { color: 'white' },
                }}
              >
                <FacebookIcon />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="subtitle1" gutterBottom>
              Company
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
              <Box component="li" sx={{ mb: 1 }}>
                <Link component={RouterLink} to="/about" color="inherit" underline="hover" sx={{ color: theme.palette.grey[400] }}>
                  About Us
                </Link>
              </Box>
              <Box component="li" sx={{ mb: 1 }}>
                <Link component={RouterLink} to="/careers" color="inherit" underline="hover" sx={{ color: theme.palette.grey[400] }}>
                  Careers
                </Link>
              </Box>
              <Box component="li" sx={{ mb: 1 }}>
                <Link component={RouterLink} to="/blog" color="inherit" underline="hover" sx={{ color: theme.palette.grey[400] }}>
                  Blog
                </Link>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="subtitle1" gutterBottom>
              Product
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
              <Box component="li" sx={{ mb: 1 }}>
                <Link component={RouterLink} to="/features" color="inherit" underline="hover" sx={{ color: theme.palette.grey[400] }}>
                  Features
                </Link>
              </Box>
              <Box component="li" sx={{ mb: 1 }}>
                <Link component={RouterLink} to="/calculator" color="inherit" underline="hover" sx={{ color: theme.palette.grey[400] }}>
                  Cost Savings
                </Link>
              </Box>
              <Box component="li" sx={{ mb: 1 }}>
                <Link component="a" href="#" color="inherit" underline="hover" sx={{ color: theme.palette.grey[400] }}>
                  Documentation
                </Link>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" gutterBottom>
              Contact
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
              <Box component="li" sx={{ mb: 1, color: theme.palette.grey[400] }}>
                Email: info@TicketSidekick.com
              </Box>
              <Box component="li" sx={{ mb: 1, color: theme.palette.grey[400] }}>
                Phone: (415) 754-9214
              </Box>
              <Box component="li" sx={{ mb: 1 }}>
                <Link component={RouterLink} to="/contact" color="inherit" underline="hover" sx={{ color: theme.palette.grey[400] }}>
                  Contact Us
                </Link>
              </Box>
            </Box>
            
            {/* No inline Calendly widget here since we're using the badge widget */}
          </Grid>
        </Grid>

        <Divider sx={{ my: 4, borderColor: theme.palette.grey[800] }} />

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body2" color="text.secondary" sx={{ color: theme.palette.grey[500] }}>
            © {currentYear} TicketSidekick. All rights reserved.
          </Typography>
          <Box>
            <Link component="a" href="/privacy-policy" color="inherit" underline="hover" sx={{ color: theme.palette.grey[500], mr: 2 }}>
              Privacy Policy
            </Link>
            <Link component="a" href="#" color="inherit" underline="hover" sx={{ color: theme.palette.grey[500] }}>
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer