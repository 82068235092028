import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { 
  Box, 
  Container, 
  Typography, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia,
  Paper,
  Stack,
  Divider,
  useTheme
} from '@mui/material';
import SupportIcon from '@mui/icons-material/Support';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import BuildIcon from '@mui/icons-material/Build';
import SecurityIcon from '@mui/icons-material/Security';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DashboardPreview from '../components/DashboardPreview';
import HowItWorksSection from '../components/HowItWorksSection';

// Feature cards data
const features = [
  {
    icon: <AutorenewIcon fontSize="large" color="primary" />,
    title: 'AI-Powered Ticket Automation',
    description: 'Reduce manual workload with intelligent ticket categorization and routing'
  },
  {
    icon: <QueryStatsIcon fontSize="large" color="primary" />,
    title: 'Advanced Analytics',
    description: 'Gain insights into support metrics and identify trends to improve service quality'
  },
  {
    icon: <IntegrationInstructionsIcon fontSize="large" color="primary" />,
    title: 'Seamless Integration',
    description: 'Works with your existing ticketing systems and knowledge bases'
  },
  {
    icon: <BuildIcon fontSize="large" color="primary" />,
    title: 'Customizable Workflows',
    description: 'Tailor processes to match your specific support requirements'
  },
  {
    icon: <SecurityIcon fontSize="large" color="primary" />,
    title: 'Enterprise-Grade Security',
    description: 'Keep your customer data secure with our advanced protection measures'
  },
  {
    icon: <SupportIcon fontSize="large" color="primary" />,
    title: 'Human-AI Collaboration',
    description: 'Augment your support team\'s capabilities with AI assistance'
  }
];

// Testimonials data
const testimonials = [
  {
    quote: "TicketSidekick reduced our average response time by 45% and helped us scale our support operations without adding headcount.",
    author: "Sarah Johnson",
    role: "Customer Support Director",
    company: "Enterprise customer"
  },
  {
    quote: "The customized integration with our existing systems was smooth, and the results were immediate. Our team loves having an AI assistant to handle repetitive tickets.",
    author: "Michael Chen",
    role: "IT Operations Manager",
    company: "Enterprise customer"
  }
];

const HomePage = () => {
  const theme = useTheme();
  
  return (
    <Box sx={{ overflow: 'hidden' }}>
      {/* Hero Section */}
      <Box 
        sx={{ 
          position: 'relative',
          color: 'white',
          py: { xs: 10, md: 16 },
          overflow: 'hidden'
        }}
      >
        {/* Background gradient */}
        <Box 
          sx={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle at top right, rgba(77, 123, 252, 0.15) 0%, rgba(17, 24, 39, 0) 70%)'
          }}
        />
        
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <Typography 
                variant="h1" 
                component="h1" 
                gutterBottom 
                sx={{ 
                  fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' },
                  lineHeight: 1.1,
                  mb: 3
                }}
              >
                AI-Powered Support 
                <br />
                <Box 
                  component="span" 
                  sx={{ 
                    background: 'linear-gradient(90deg, #4d7bfc 0%, #b7fa37 100%)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    color: 'transparent',
                    display: 'inline-block'
                  }}
                >
                  for modern teams.
                </Box>
              </Typography>
              <Typography 
                variant="h5" 
                sx={{ 
                  mb: 5, 
                  opacity: 0.9,
                  maxWidth: '600px',
                  fontWeight: 400
                }}
              >
                TicketSidekick is a smart support agent that manages tech support automatically across the entire incident lifecycle.
              </Typography>
              <Stack 
                direction={{ xs: 'column', sm: 'row' }} 
                spacing={3}
                sx={{ mb: { xs: 6, md: 0 } }}
              >
                <Button 
                  variant="contained" 
                  color="primary" 
                  size="large"
                  component={RouterLink}
                  to="/calculator?r=hero"
                  endIcon={<ArrowForwardIcon />}
                  sx={{ 
                    py: 1.5,
                    px: 3,
                    fontSize: '1.1rem'
                  }}
                >
                  Triage Efficiency Calculator
                </Button>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  size="large"
                  component={RouterLink}
                  to="/features?r=hero"
                  sx={{ 
                    py: 1.5,
                    px: 3,
                    fontSize: '1.1rem',
                    borderColor: 'rgba(77, 123, 252, 0.5)',
                    '&:hover': {
                      borderColor: 'primary.main',
                    }
                  }}
                >
                  Explore Features
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box sx={{ 
                position: 'relative', 
                textAlign: 'center',
                mt: { xs: 4, md: 0 }
              }}>
                <Paper 
                  elevation={16} 
                  sx={{ 
                    p: 0, 
                    borderRadius: 4, 
                    background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.7) 0%, rgba(31, 41, 55, 0.5) 100%)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    height: 440,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0 20px 40px rgba(0, 0, 0, 0.3)',
                    transform: 'perspective(1500px) rotateY(-5deg)',
                    transformStyle: 'preserve-3d',
                    overflow: 'hidden'
                  }}
                >
                  <DashboardPreview />
                </Paper>
                
                {/* Decorative elements */}
                <Box 
                  sx={{ 
                    position: 'absolute',
                    width: 120,
                    height: 120,
                    borderRadius: '50%',
                    background: 'rgba(77, 123, 252, 0.3)',
                    filter: 'blur(40px)',
                    top: -40,
                    right: -20,
                    zIndex: -1
                  }} 
                />
                <Box 
                  sx={{ 
                    position: 'absolute',
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    background: 'rgba(183, 250, 55, 0.3)',
                    filter: 'blur(30px)',
                    bottom: -30,
                    left: 20,
                    zIndex: -1
                  }} 
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
        
        {/* Background decorative elements */}
        <Box sx={{ 
          position: 'absolute', 
          top: '10%', 
          right: '5%', 
          width: '300px', 
          height: '300px', 
          borderRadius: '50%', 
          background: 'radial-gradient(circle, rgba(77, 123, 252, 0.15) 0%, rgba(17, 24, 39, 0) 70%)',
          zIndex: 0
        }} />
        <Box sx={{ 
          position: 'absolute', 
          bottom: '5%', 
          left: '10%', 
          width: '250px', 
          height: '250px', 
          borderRadius: '50%', 
          background: 'radial-gradient(circle, rgba(183, 250, 55, 0.1) 0%, rgba(17, 24, 39, 0) 70%)',
          zIndex: 0
        }} />
      </Box>

      {/* Main Value Proposition */}
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Understand and improve the ROI of developer time with AI
          </Typography>
        </Box>
        
        <Grid container spacing={4} justifyContent="center">
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card 
                sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 20px 30px rgba(0, 0, 0, 0.3)',
                  }
                }}
              >
                <CardContent sx={{ padding: 4, flexGrow: 1, textAlign: 'center' }}>
                  <Box sx={{ mb: 3 }}>
                    {feature.icon}
                  </Box>
                  <Typography variant="h5" component="h3" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* How It Works Section */}
      <HowItWorksSection />

      {/* Testimonials */}
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ mb: 8 }}>
          What Our Clients Say
        </Typography>
        
        <Grid container spacing={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card 
                sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
                  }
                }}
              >
                <CardContent sx={{ flexGrow: 1, p: 4 }}>
                  <Typography variant="body1" paragraph sx={{ fontStyle: 'italic', mb: 3, fontSize: '1.125rem' }}>
                    "{testimonial.quote}"
                  </Typography>
                  <Divider sx={{ mb: 2, opacity: 0.1 }} />
                  <Typography variant="subtitle1" fontWeight="bold">
                    {testimonial.author}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {testimonial.role}, {testimonial.company}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* CTA Section */}
      <Box 
        sx={{ 
          py: 10, 
          background: 'linear-gradient(135deg, rgba(77, 123, 252, 0.9) 0%, rgba(56, 92, 196, 0.9) 100%)',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        {/* Background decorative elements */}
        <Box 
          sx={{ 
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: '100%',
            background: 'radial-gradient(circle at bottom right, rgba(183, 250, 55, 0.3) 0%, rgba(17, 24, 39, 0) 50%)',
            zIndex: 0
          }} 
        />
        
        <Container maxWidth="md" sx={{ textAlign: 'center', position: 'relative', zIndex: 1 }}>
          <Typography variant="h2" component="h2" gutterBottom>
            Ready to Transform Your Support Operations?
          </Typography>
          <Typography variant="h5" sx={{ mb: 6, opacity: 0.9, fontWeight: 400 }}>
            Get in touch for a customized demo and consultation tailored to your business needs.
          </Typography>
          <Button 
            variant="contained" 
            color="secondary"
            size="large"
            component={RouterLink}
            to="/contact"
            endIcon={<ArrowForwardIcon />}
            sx={{ 
              py: 1.5, 
              px: 4,
              fontSize: '1.1rem',
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)'
            }}
          >
            Contact Us Today
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

export default HomePage;